.about_hero {
  position: relative;
  width: 100vw;
  height: 625px;
}

.about_hero img {
  position: absolute;
  /* top: -30px; */
  left: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  object-fit: cover;
}

.about_hero h1 {
  /* background-color: rgba(0, 0, 0, 0.3); */
  top: -30px;
  left: 0;
  z-index: 1;
  position: absolute;
  color: #fff;
  /* top: -60px; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-weight: 500;
}

.about_text {
  margin-top: 40px;
  padding: 20px 100px;
  margin-bottom: 20px;
}

.about_text p {
  text-align: justify;
  font-size: 28px;
  line-height: 38px;
}

/* about us section */

.about_us {
  background-color: #f6bfb0;
  padding: 50px 70px;
  color: #fff;
}

.about_us_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 50px;
}

.about_us_top img {
  width: 450px;
  height: 300px;
  object-fit: contain;
  margin-right: 60px;
}

.about_us_middle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 50px;
}

.about_us_middle img {
  width: 450px;
  height: 300px;
  object-fit: contain;
  margin-left: 60px;
}

.about_us_text_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -40px;
}

.about_us_middle_text_group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -40px;
}

.about_us_middle_text_group h2 {
  text-decoration: underline;
  margin-bottom: 10px;
  font-size: 48px;
  font-weight: 700;
}
.about_us_middle_text_group p {
  font-size: 32px;
  font-weight: 500;
  margin-top: 0;
  text-align: right;
}

.about_us_text_group h2 {
  text-decoration: underline;
  margin-bottom: 10px;
  font-size: 48px;
  font-weight: 700;
}
.about_us_text_group p {
  font-size: 32px;
  font-weight: 500;
  margin-top: 0;
  text-align: left;
}

@media (max-width: 1000px) {
  .about_hero {
    position: relative;
    width: 100vw;
    height: 185px;
  }
  .about_text {
    margin-top: 0;
    padding: 20px 50px;
    margin-bottom: 20px;
  }

  .about_text p {
    text-align: justify;
    font-size: 18px;
    line-height: 38px;
  }

  .about_us_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }

  .about_us_top img {
    width: 450px;
    height: 300px;
    object-fit: contain;
    margin-right: 0;
    margin-bottom: 30px;
    padding-top: 20px;
  }

  .about_us_text_group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    text-align: center;
  }

  .about_us_text_group h2 {
    text-decoration: underline;
    margin-bottom: 10px;
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    width: 100%;
  }

  .about_us_text_group p {
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
    width: 100%;
    text-align: center;
  }

  .about_us_middle {
    /* height: 600px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
  }

  .about_us_middle img {
    position: absolute;
    width: 450px;
    height: 300px;
    object-fit: contain;
    margin-left: 0;
  }

  .about_us_middle_text_group p {
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
    width: 100%;
    text-align: center;

  }

  .about_us_middle_text_group h2 {
    text-decoration: underline;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    font-size: 48px;
    font-weight: 700;
    padding-top: 370px;
  }
}
