.media_gall_container {
  background-color: #000;
  padding-top: 10%;
  margin: 0 auto;
}

.media_gall_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
}

.media_gall_group img {
  width: 270px;
  height: 240px;
  object-fit: cover;
  margin: 0 20px 20px;
}

.media_gall_icons {
  background-color: #red;
  width: 100%;
}
.media_gall_icon {
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
}

/* PORTFOLIO MODAL */

.portfolio_modal {
  display: fixed; /* Hidden by default */
  align-items: center;
  justify-content: center;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
  outline: none;
}

.portfolio_modal_close {
  position: absolute;
  z-index: 9;
  top: 80px;
  right: 40px;
  height: 30px;
  width: 30px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.portfolio_modal_close:hover {
  transform: translateY(-3px);
  cursor: pointer;
  color: red;
}

.portfolio_modal_img_container {
  width: 80%;
  height: 95%;
  margin: 0 auto;
  outline: none;
}

.portfolio_sliderStyle {
  height: 100%;
  width: 100%;
  outline: none;
}

.portfolio_modal_img {
  width: 80vw;
  height: 80vh;
  object-fit: contain;
  margin: 0 auto;
  outline: none;
}

@media (max-width: 1024px) {
  .portfolio_modal_img_container {
    width: 100%;
    height: 80vh;
    margin: 0 auto;
    outline: none;
  }

  .portfolio_modal_img {
    width: 100%;
    height: 80vh;
    object-fit: contain;
    margin: 0 auto;
    outline: none;
  }
}
