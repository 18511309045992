.App {
  height: 100vh;
  text-align: center;
  scroll-behavior: smooth;
}

*:focus {
  outline: none !important;
}

a {
  color: #fff;
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

li {
  list-style-type: none;
}

.App-header {
  /* background-color: #000; */
  min-height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  color: white;
  width: 100vw;
  position: absolute;
  z-index: 100;
}

.logo-container {
  padding-left: 5%;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.logo-container:hover {
  transform: translateY(-3px);
}

.App-header img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  /* padding-left: 10%; */
}

.header_links {
  display: none;
  flex-direction: row;
  padding-right: 10%;
}

.header_links li {
  margin-right: 30px;
  font-weight: 700;
  font-size: 18px;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.header_links li:hover {
  cursor: pointer;
  transform: translateY(-2px);
}

@media (min-width: 1000px) {
  .header_links {
    display: flex;
    flex-direction: row;
    padding-right: 10%;
  }
  .header_navIcon {
    display: none;
  }
}

.header_navIcon {
  padding-right: 5%;
  font-size: 45px;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.header_navIcon:hover {
  cursor: pointer;
}
.header_navIcon:active {
  transform: scale(1.5);
}

/* sidebar section */
.sidebar {
  display: none;

  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.95);
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  animation-name: side;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.1, 1);
  animation-fill-mode: forwards;
}

@keyframes side {
  from {
    transform: translateX(90%);
  }
  to {
    transform: translateX(0);
  }
}

.sidebar_links {
  padding-top: 20%;
}

.sidebar_links li {
  font-size: 50px;
  font-weight: 600;
  padding-left: 10%;
  margin-bottom: 20%;
  text-align: left;
  color: #fff;
}

/* FOOTER section */

.footer {
  display: flex;
  flex-direction: row;
  background-color: #000;
  color: #fff;
  justify-content: space-between;
  padding: 60px 60px;
}

.footer_group1 img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.footer_icons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}
.footer_icon {
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.footer_icon:hover {
  color: #eba107;
  transform: translateY(-3px);
  cursor: pointer;
}

@media (max-width: 1000px) {
  .footer {
    display: flex;
    flex-direction: column;
    background-color: #000;
    color: #fff;
    justify-content: space-between;
    padding: 60px 60px;
  }

  .footer_group1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
  }

  .footer_group1 img {
    width: 70px;
    height: 70px;
    object-fit: contain;
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .footer_icons {
    position: absolute;
    margin-top: 60px;
    margin-left: -20px;
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-around;
  }
}

.footer_group {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
}

.footer_group ul {
  text-align: left;
  padding: 0;
}
.footer_group h3 {
  text-align: left;
  margin-bottom: 0;
}
.footer_group li {
  margin-bottom: 10px;
}
