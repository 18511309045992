.home_container {
  /* overflow: hidden; */
}
.home_hero {
  background-color: #000;
  height: 600px;
  padding-top: 90px;
}

.homily {
  display: flex;
  flex-direction: row;
  height: 500px;
  align-items: center;
  padding-right: 10%;
}

.homily_left {
  height: 300px;
  width: 100%;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 5%;
}

.homily_left h3 {
  font-weight: 700;
  font-size: 18px;
}

.homily_left img {
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.homily_right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.homily_right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.homily_right h2 {
  font-size: 36px;
  font-weight: 700;
}
.homily_right p {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  margin-top: 0;
}
.homily_right hr {
  height: 10px;
  background-color: red;
}

.homily_hr {
  width: 20px;
  border-bottom: 1px solid #000;
}

@media (max-width: 1000px) {
  .homily {
    display: flex;
    flex-direction: column;
    height: 600px;
    align-items: center;
    justify-content: center;
    padding-right: 0;
    padding: 10%;
  }

  .homily_left {
    height: 250px;
    width: 70%;
    background-color: #000;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0;
  }

  .homily_right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .homily_right h2 {
    width: 100%;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
  }

  .homily_right p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    width: 100%;
  }
}

/* Pastors section  */

.home_pastors {
  /* height: 400px; */
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 40px 100px 40px;
  background-color: #eba107;
  text-align: left;
  color: #fff;
  -webkit-clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
  position: relative;
}

.home_pastors h1 {
  font-size: 55px;
}
.home_pastors p {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: justify;
  z-index: 99;
  width: 65%;
}
.pastors{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 90%;
  position: absolute;
  bottom: 0;
  right: 8%;
  z-index: -1;
}
.home-pastors-img1{
  width: 280px;
  height: 350px;
  object-fit: 'contain';
} 
.home-pastors-img2{
  width: 280px;
  height: 350px;
  object-fit: 'contain';
} 

@media (max-width: 1000px) {
  .home_pastors {
    display: flex;
    flex-direction: column;
    padding: 100px 40px 20px;
    background-color: #eba107;
    text-align: left;
    color: #fff;
    -webkit-clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
  }

  .home_pastors p {
    width: 100%;
  }

  .pastors{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    position: relative;
    z-index: -1;
  }
  .home-pastors-img1{
    object-fit: 'contain';
    position: relative;
    z-index: -1;
    width: 300px;
    height: 304px;
    margin: 0 auto 40px;
    transform: rotate(5deg);
  } 
  
  .home-pastors-img2{
    object-fit: 'contain';
    position: relative;
    z-index: -1;
    width: 300px;
    height: 304px;
    margin: 0 auto 40px;
    transform: rotate(-10deg);
  } 
 
}

/* events section */

.events {
  display: flex;
  flex-direction: row;
  margin-bottom: 5%;
  /* width: 100%; */
  justify-content: space-between;
}
.events h1 {
  font-size: 48px;
  font-weight: 400px;
  text-align: left;
  margin-bottom: 5px;
}

.events_hr {
  border-bottom: 5px solid #000;
  width: 200px;
  margin-bottom: 5%;
}

.events_group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  overflow-x: scroll;
}

.events_card {
  margin-right: 50px;
}

.events_card img {
  width: 350px;
  height: 300px;
  object-fit: cover;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.events_card img:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}

.events_section {
  padding-top: 3%;
  padding-left: 5%;
  width: 90%;
}

.event_text {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.5em;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

/* give section */

.home_give {
  position: relative;
  z-index: 1;
  height: 550px;
  overflow: hidden;
  color: #fff;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%);
}

.home_give_bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.home_give_logo {
  position: absolute;
  right: 10%;
  top: 15%;
  height: 110px;
  width: 110px;
  object-fit: cover;
}

.give_group {
  text-align: left;
  padding-left: 5%;
}

.give_group h2 {
  font-size: 80px;
  font-weight: 800px;
  /* -webkit-text-stroke: 1px black; */
  /* -webkit-text-fill-color: none; */
}

.give_group_title {
  position: relative;
  padding-top: 8%;
}

.give_group_title1 {
  position: absolute;
  color: red;
  margin: 30px 0 0 60px;
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: transparent;
}
.give_group_title2 {
  position: absolute;
  margin: 0 0 0 30px;
  z-index: 1;
}
.give_group_title3 {
  position: absolute;
  margin-top: -35px;
  color: green;
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: transparent;
}

.give_group_body {
  padding-top: 8%;
  padding-bottom: 10%;
}

.give_group_body p {
  font-size: 50px;
  margin-bottom: 50px;
}

@media (max-width: 10000px) {
  .home_give {
    padding-top: 40px;
    padding-left: 20px;
  }
  .give_group h2 {
    font-size: 60px;
    font-weight: 600px;
    /* -webkit-text-stroke: 1px black; */
    /* -webkit-text-fill-color: none; */
  }

  .give_group_body p {
    font-size: 40px;
    margin-bottom: 50px;
    padding-top: 30px;
    width: 80%;
  }

  .home_give_logo {
    position: absolute;
    right: 5%;
    top: 5%;
    height: 50px;
    width: 50px;
    object-fit: cover;
  }
}

.give_action {
  display: flex;
  align-items: center;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.give_action:hover {
  transform: translateY(-3px);
}

.give_action:hover h2 {
  color: #594548;
}

.give_action h2 {
  font-size: 70px;
  margin-right: 20px;
  margin-top: 0;
  margin-bottom: 0;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.give_icon {
  font-size: 70px;
}

/* subscription section */

.home_sub {
  position: relative;
  height: 600px;
  overflow: hidden;
  color: #fff;
  margin-top: -60px;
}

.home_sub_hero {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.home_sub_section {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home_sub_section img {
  width: 130px;
  height: 130px;
  object-fit: contain;
  margin-right: 8%;
}

.home_sub_title {
  font-size: 18px;
  align-items: justify;
  transform: rotate(90deg);
  letter-spacing: 0.5em;
}

.sub_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 1000px) {
  .home_sub {
    position: relative;
    height: 650px;
    overflow: hidden;
    color: #fff;
    margin-top: -60px;
  }
  .home_sub_section {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 90px;
  }

  .home_sub_section img {
    width: 130px;
    height: 130px;
    object-fit: contain;
    margin-right: 0;
  }

  .home_sub_title {
    font-size: 18px;
    align-items: justify;
    transform: rotate(0deg);
    letter-spacing: 0.5em;
  }

  .sub_group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.sub_group h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 40px;
}

.sub_group p {
  text-align: left;
  line-height: 20px;
  margin-top: 0;
  font-size: 20px;
}

.home_sub_form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  background-color: red;
  width: 450px;
  background-color: #fff;
  border-radius: 40px;
  overflow: hidden;
}

.home_sub_form input {
  border: none;
  height: 40px;
  width: 450px;
  border-radius: 40px;
  padding-left: 20px;
}

.home_form_icon_container {
  background-color: #32549f;
  width: 70px;
  height: 50px;
  border-bottom-left-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_form_icon {
  font-size: 21px;
}

/* form */

.mail_form_container {
  background-color: #fff;
  height: 50px;
  border-radius: 40px;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* position: relative; */
}

.chimp_form input {
  padding: 15px 20px;
  width: 300px;
  border-radius: 40px;
  border: none;
}
.chimp_form button {
  border: none;
  padding: 15px 20px;
  border-radius: 20px;
  color: #fff;
  background-color: #32549f;
  margin-top: 3px;
  margin-right: 3px;
}
.chimp_form button:hover {
  cursor: pointer;
}
.msg-alert {
  margin-top: 10px;
  color: #fff;
}
.msg-alert p {
  color: #fff;
}
