.impact_container {
  overflow: hidden;
}

.impact_top {
  background-color: #000;
  padding: 50px;
  /* height: 20%; */
}

.impact_read_test {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 120px 50px;
}

.impact_read_texts {
  text-align: left;
  padding-left: 70px;
}

.impact_read_texts h2 {
  font-size: 36px;
  font-weight: 700;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.impact_read_texts h2:hover {
  cursor: pointer;
  color: #eba107;
  transform: translateY(-3px);
}

.impact_read_texts p {
  font-size: 24px;
  font-weight: 400;
  width: 300px;
  line-height: 30px;
}

.impact_test_images_group img {
  width: 210px;
  height: 210px;
  object-fit: cover;
  margin-right: 15px;
}

.impact_images {
  width: 210px;
  height: 210px;
  object-fit: cover;
  margin-right: 15px;
}

.impact_test_images_group img:focus {
  outline: 0;
}

.slider {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 60%;
}

.slider_images_container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1000px) {
  .impact_read_test {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px 50px 60px;
  }

  .impact_read_texts {
    text-align: left;
    padding-left: 0;
    margin-bottom: 20px;
  }

  .impact_test_images_group {
    display: flex;
    flex-direction: row;
    background-color: red;
    margin-left: 90%;
    overflow-y: scroll;
  }

  .impact_test_images_group img {
    width: 250px;
    height: 250px;
    object-fit: cover;
    margin-right: 15px;
  }
  .slider {
    display: flex;
    flex-direction: row;
    width: 140%;
  }
}

/* impact watch section */

.impact_watch_test {
  display: flex;
  flex-direction: row;
  /* height: 800px; */
}

.impact_watch_left {
  background-color: #000;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
}

.impact_watch_left img {
  width: 400px;
  height: 350px;
  object-fit: cover;
}

.impact_watch_right {
  margin-left: -150px;
  width: 60%;
  background-color: #eba107;
  color: #fff;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 180px;
  padding-top: 100px;
}

/* responseive impact */
@media (max-width: 1000px) {
  .impact_watch_test {
    display: flex;
    flex-direction: column;
    height: 700px;
  }

  .impact_watch_left {
    padding: 40px 0;
    background-color: #000;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 97%);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 97%);
  }

  .impact_watch_right {
    margin-left: 0;
    width: 100%;
    margin-top: -15px;
    background-color: #eba107;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.impact_watch_right h2 {
  font-size: 36px;
  font-weight: 700;
}
.impact_watch_right p {
  font-size: 24px;
  font-weight: 500;
  width: 350px;
  margin-top: 0;
  margin-bottom: 40px;
}
.impact_watch_right button {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 8px 20px;
  border-radius: 40px;
  font-size: 21px;
  font-weight: 500;
}

/* impact shaer section */

.impact_share {
  display: flex;
  flex-direction: row;
  height: 600px;
  align-items: center;
}

.impact_share_left {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  height: 100%;
  width: 50%;
}
.impact_share_left h2 {
  font-size: 36px;
  font-weight: 700;
  text-align: left;
  padding-left: 80px;
}
.impact_share_left p {
  font-size: 25px;
  font-weight: 500;
  width: 450px;
  margin-top: 0;
  padding-left: 80px;
}

.impact_share_right {
  /* background-color: blue; */
  padding-top: 50px;
  padding-right: 40px;
  /* height: 100%; */
  width: 50%;
}

.impact_form_group {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1000px) {
  .impact_share {
    display: flex;
    flex-direction: column;
    height: 1000px;
    align-items: center;
    /* justify-content: center; */
  }

  .impact_share_left {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 30%;
    width: 100%;
  }

  .impact_share_left h2 {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    padding-left: 0;
    margin-bottom: 0;
  }
  .impact_share_left p {
    font-size: 25px;
    font-weight: 500;
    width: 80%;
    /* padding: 40px 0; */
    margin-top: 0;
    padding-left: 0;
    text-align: center;
  }

  .impact_share_right {
    /* background-color: blue; */
    padding-top: 0;
    padding-right: 0;
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .impact_form_group {
    display: flex;
    flex-direction: column;
  }
}

.impact_share_form {
  display: flex;
  flex-direction: column;
}

.impact_share_form input {
  width: 400px;
  height: 20px;
  border: 1.5px solid #eba107;
  padding: 8px 20px;
  border-radius: 20px;
  margin: 20px 10px;
  font-size: 16px;
}
.impact_share_form textarea {
  width: 400px;
  height: 200px;
  border: 1.5px solid #eba107;
  padding: 8px 20px;
  border-radius: 20px;
  margin: 20px 10px;
  font-size: 16px;
}
.impact_share_form button {
  padding: 8px 20px;
  background: #eba107;
  color: #fff;
  border: none;
  border-radius: 20px;
  width: 120px;
  margin: 20px 10px;
  font-size: 18px;
  font-weight: 400;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.impact_share_form button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  background-color: #000;

  cursor: pointer;
  transform: translateY(-3px);
}

/* testimony modal */

.testimony_modal {
  background-color: #000;
  color: #fff;
  position: fixed;
  z-index: 2;
  height: 60vh;
  overflow: hidden;
  margin: 10px 100px 40px;
  padding: 60px;
  overflow-y: scroll;
  border-radius: 10px;
}

.testimony_group {
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 20px 60px;
  margin-bottom: 23px;
}

.testimony_button {
  width: 30px;
  height: 30px;
  color: #000;
  background-color: #fff;
  border-radius: 15px;
  position: absolute;
  right: 20px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.testimony_button:hover {
  transform: translateY(-3px);
  color: red;
  cursor: pointer;
}
