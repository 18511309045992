.giving_top {
  background-color: #000;
  padding: 50px;
}

.giving_group {
  display: flex;
  flex-direction: row;
  height: 700px;
}

.giving {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.giving img {
  width: 450px;
  height: 600px;
  object-fit: contain;
}

@media (max-width: 1000px) {
  .giving_group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 50px;
  }
  .giving {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .giving img {
    width: 400px;
    height: 300px;
    object-fit: contain;
  }
}

.giving_form_container {
  width: 60%;
  margin-right: 20px;
  text-align: left;
  padding-top: 50px;
}

.giving_form {
  display: flex;
  flex-direction: column;
}
.giving_form input {
  height: 20px;
  border: 1.5px solid #eba107;
  padding: 8px 20px;
  border-radius: 20px;
  margin: 20px 10px;
  font-size: 16px;
}
.giving_form button {
  padding: 8px 20px;
  background: #eba107;
  color: #fff;
  border: none;
  border-radius: 20px;
  margin: 20px 10px;
  font-size: 18px;
  font-weight: 400;
}

.giving_form_numbers {
  border: 1.5px solid #eba107;
  border-radius: 20px;
  padding: 10px 20px;
}

.giving_form_top {
  display: flex;
  flex-direction: row;
}

.giving_form_top input{
  width: 100%;
}

.accounts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.account_type {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}
.account_type img {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-right: 10px;
}
.account_type p {
  margin: 0;
}

.account-details p{
  margin-bottom: 0;
  margin-top: 8px;
  font-size: 14px;
  text-align: left;
}
@media (max-width: 1000px) {
  .giving_form input {
    width: 300px;
    height: 20px;
    border: 1.5px solid #eba107;
    padding: 8px 20px;
    border-radius: 20px;
    margin: 20px 10px;
    font-size: 16px;
  }
  .giving_form_numbers {
    border: 1.5px solid #eba107;
    border-radius: 20px;
    margin: 20px;
    align-self: center;
  }

  .giving_form_container {
    width: 100%;
    margin-right: 0;
    text-align: center;
    padding-top: 50px;
  }
  .giving_form_container h2 {
    text-align: center;
  }

  .giving_form_top {
    display: flex;
    flex-direction: column;
    padding-right: 0;
  }
  .giving_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .accounts {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }

  .account_item{
    margin-bottom: 20px;
  }
}
