.media_hero {
  height: 500px;
  background-color: #000;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.media_hero img {
  margin: 0 auto;
  width: 120px;
  height: 120px;
  object-fit: contain;
}

.media_icons {
  position: absolute;
  left: 100px;
  top: 200px;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1000px) {
  .media_hero {
    height: 500px;
    background-color: #000;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .media_icons {
    position: absolute;
    top: 400px;
    left: 130px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;
  }
}

.media_icon {
  color: #fff;
  margin-bottom: 30px;
}

.media_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.media_slider {
  display: flex;
  flex-direction: row;
  height: 250px;
  width: 100%;
}

.media_card {
  position: relative;
  height: 250px;
  width: 20%;
}
.media_card:hover {
  cursor: pointer;
}

.media_card img {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media_images {
  z-index: 3;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  object-fit: cover;
}

.media_group_icon {
  z-index: 3;
  object-fit: contain;
}
.media_icon_cont {
  position: absolute;
  margin-left: 40%;
  margin-top: 40%;
  z-index: 3;
  height: 50px;
  width: 50px;
  object-fit: contain;
}

@media (max-width: 1000px) {
  .media_section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .media_card {
    position: relative;
    height: 250px;
    width: 100%;
  }
  .media_icon_cont {
    position: absolute;
    margin-left: 45%;
    margin-top: 20%;
    z-index: 3;
    height: 50px;
    width: 50px;
    object-fit: contain;
  }
}

.media_card h4 {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 4;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}
.media_text {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 4;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}
